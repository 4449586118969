<template>
  <div>
    <v-btn
      fab
      x-small
      depressed
      :color="assisted ? 'success' : 'error'"
      :loading="loading"
      @click="handleAssistance(!assisted, assistanceId)"
    >
      <v-icon size="18">
        {{ assisted ? "mdi-check" : "mdi-close" }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    assisted: { type: Boolean, default: false },
    assistanceId: { type: Number, required: true },
    loading: { type: Boolean, default: false },
  },
  methods: {
    handleAssistance(assisted, assistance_id) {
      const params = { assisted, assistance_id };
      this.$emit("handleAssistance", params);
    },
  },
};
</script>

<style scoped></style>
